@import "./src/styles/main.scss";

m-step {
  flex: initial;
  min-width: 110px;

  &:before {
    display: none;
  }
  &:after {
    background-color: var(--status-cancelled-base);
  }
  &[completed]:not(:has(~ [completed])):after {
    background-color: $m-color-text-disabled !important;
  }
  &[completed]:before {
    display: none !important;
  }
  &[completed] + m-step:before {
    display: block;
    width: 50%;
    z-index: 1;
    right: calc(100% + var(--m-steps-circle-size) / 2 + var(--m-steps-circle-margin-inline-end));
  }
}

.color-text-disabled {
  m-step {
    &:after,
    &:before {
      background-color: $m-color-text-disabled;
    }
  }
}
.requested {
  m-step {
    &[completed]:after,
    &[completed] + m-step:before {
      background-color: var(--status-requested-base);
    }
  }
}
.scheduled {
  m-step {
    &[completed]:after,
    &[completed] + m-step:before {
      background-color: var(--status-scheduled-base);
    }
  }
}
.arrived {
  m-step {
    &[completed]:after,
    &[completed] + m-step:before {
      background-color: var(--status-arrived-base);
    }
  }
}
.inprogress {
  m-step {
    &[completed]:after,
    &[completed] + m-step:before {
      background-color: var(--status-inprogress-base);
    }
  }
}
.completed {
  m-step {
    &[completed]:after,
    &[completed] + m-step:before {
      background-color: var(--status-completed-base);
    }
  }
}
.cancelled {
  m-step {
    &[completed]:after,
    &[completed] + m-step:before {
      background-color: var(--status-cancelled-base);
    }
  }
}

m-steps {
  $mobileStatusTimeline: 1280px;
  @media (max-width: $mobileStatusTimeline) {
    flex-direction: column;
    justify-content: flex-start;
  }

  m-step {
    @media (max-width: $mobileStatusTimeline) {
      flex-direction: row;
      text-align: left !important;
      * {
        font-size: 14px !important;
      }
    }
    &:before {
      @media (max-width: $mobileStatusTimeline) {
        display: none !important;
      }
    }
    &:after {
      $barHeight: 16px;
      @media (max-width: $mobileStatusTimeline) {
        width: 2px;
        height: $barHeight;
        left: 11px;
        top: calc(100% + (#{$barHeight} / 2));
      }
    }
  }
}
