@import "./src/styles/main.scss";

::v-deep {
  .company-table {
    tr {
      &:hover {
        cursor: pointer;
      }
    }
  }
}
#select-company-footer {
  flex: 1;
  align-items: flex-end;
}
#not-found-container {
  max-width: 30%;
  min-width: 320px;
}

m-tabs {
  --m-tabs-border-width: 0;
}
m-tab-panel {
  padding: $m-spacing-6 0;
}

:root {
  m-tabs {
    --m-tabs-border-width: 0 !important;
  }
}

.tabs {
  @media (max-width: $tabletBreakpoint) {
    width: 100%;
    text-align: left;
  }
}
