@import "./src/styles/main.scss";

.date-picker-wrapper {
  padding-top: $m-spacing-2;
  padding-bottom: $m-spacing-4;
  background-color: #fff;
  max-width: 100%;
  box-shadow: none;
}

::v-deep {
  .v-btn--active {
    background-color: $m-color-text-primary;
    color: $m-color-text-inverted !important;

    .v-btn__content {
      &:before {
        content: ' ';
        height: 1px;
        background-color: white;
        width: 10px;
        position: absolute;
        bottom: -2px;
      }
    }
  }
}

.date-picker-trigger {
  @media (max-width: $tabletBreakpoint) {
    width: 100% !important;
  }
}
