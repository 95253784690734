@import "./src/styles/main.scss";

.big-number-box {
  cursor: pointer;
  // TODO ask sam how to get the right colors here
  border: solid 1px rgba(var(--m-color-border, 193, 206, 217), 1);
  background-color: #f8f9fb;
  width: 110px;
  &.selected {
    background-color: #cadcdd;
  }
}
