@import "./src/styles/main.scss";

:root {
  m-dialog {
    --margin: 100px;
    @media (max-width: $tabletBreakpoint) {
      --margin: 24px;
    }
  }
}
.close-button {
  position: absolute;
  right: 0;
  top: 10px;
  margin: 16px;
  cursor: pointer;
}
