@import './font_size_theme';
@import './spacing_theme';

/**
TODO: IT's messy to have a Vuetify theme and a separate theme here.  We should find a way to utilize the Vuetify theme and
take the time to clean it up for consistency.
 */

// Background
$color-background-primary: #fcfcfc;
$color-background-secondary: #ffffff;
$color-background-tertiary: #f5f8fa;

// Accents
$color-accent-20: #e4f1f7;
$color-accent-60: #00629f;

// Lines and borders
$color-line-border: #ebebeb;
$color-line-divider: #dbdbdb;

// Neutral
$color-neutral-90: #273038;
$color-neutral-80: #585f66;
$color-neutral-70: #71727a;
$color-neutral-60: #838d96;
$color-neutral-30: #d6d9de;
$color-neutral-20: #ebeef0;
$color-neutral-10: #fcfcfc;
$color-neutral-0: #ffffff;

// Primary
$color-primary-100: #c96500;
$color-primary-60: #ff9a1a;
$color-primary-20: #ffeed9;

// Secondary
$color-secondary-60: #0e4059;

// Text
$color-text-error: #d40047;
$color-text-primary: #212121;
$color-text-secondary: #3c3c3c;
$color-text-tertiary: #656565;
$color-text-disabled: #c0c6cc;
$color-text-inverted: #ffffff;
$color-text-placeholder: #929292;
$color-text-link: #005a87;

// Statuses
$color-warning-100: #955800;
$color-warning-40: #ffce4f;
$color-warning-20: #ffeed9;
$color-success-100: #09552c;
$color-success-60: #12a455;
$color-success-20: #dcf2e6;
$color-error-20: #faebee;

// Transparencies
$color-transparent-neutral-40-20: rgba(188, 206, 212, 0.2);
$color-background-transparency-inverted: rgba(146, 146, 146, 0.12);
$color-background-transparent: transparent;

// Generic
$white: #fff;
$black: #000;
$color-scheduled: var(--v-scheduled-base);
$color-arrived: var(--v-arrived-base);
$color-completed: var(--v-completed-base);
$color-no-show: var(--v-noshow-base);
$color-cancelled: var(--v-cancelled-base);
$color-requested: var(--v-requested-base);
$color-in-progress: var(--v-inprogress-base);

// Typography
$font-weight-bold: 700;
$font-weight-medium: 600;
$font-weight-regular: 500;

$line-height-1: 100%;
$line-height-2: 125%;
$line-height-3: 130%;
$line-height-4: 140%;
$line-height-5: 150%;

// ADD NEW COLORS HERE TO MAKE SURE THEY HAVE UTILITY CLASSES
$colors: (
  'color-background-primary': $color-background-primary,
  'color-background-secondary': $color-background-secondary,
  'color-background-tertiary': $color-background-tertiary,
  'color-accent-20': $color-accent-20,
  'color-accent-60': $color-accent-60,
  'color-line-border': $color-line-border,
  'color-line-divider': $color-line-divider,
  'color-neutral-90': $color-neutral-90,
  'color-neutral-80': $color-neutral-80,
  'color-neutral-70': $color-neutral-70,
  'color-neutral-60': $color-neutral-60,
  'color-neutral-30': $color-neutral-30,
  'color-neutral-20': $color-neutral-20,
  'color-neutral-10': $color-neutral-10,
  'color-neutral-0': $color-neutral-0,
  'color-primary-100': $color-primary-100,
  'color-primary-60': $color-primary-60,
  'color-primary-20': $color-primary-20,
  'color-secondary-60': $color-secondary-60,
  'color-text-error': $color-text-error,
  'color-text-primary': $color-text-primary,
  'color-text-secondary': $color-text-secondary,
  'color-text-tertiary': $color-text-tertiary,
  'color-text-disabled': $color-text-disabled,
  'color-text-inverted': $color-text-inverted,
  'color-text-placeholder': $color-text-placeholder,
  'color-text-link': $color-text-link,
  'color-warning-100': $color-warning-100,
  'color-warning-40': $color-warning-20,
  'color-warning-20': $color-warning-20,
  'color-success-100': $color-success-100,
  'color-success-60': $color-success-60,
  'color-success-20': $color-success-20,
  'color-error-20': $color-error-20,
  'color-transparent-neutral-40-20': $color-transparent-neutral-40-20,
  'color-background-transparency-inverted': $color-background-transparency-inverted,
  'color-background-transparent': $color-background-transparent,
  'color-white': $white,
  'color-black': $black
);

// Generate utility classes for each color
@each $colorName, $value in $colors {
  .text--#{$colorName},
  .text__#{$colorName} {
    color: $value !important;
  }
  .bg__#{$colorName},
  .bg--#{$colorName},
  .#{$colorName} {
    background-color: $value !important;
  }
}

$default-border-radius: 4px;
.default-border-radius {
  border-radius: 4px;
}
