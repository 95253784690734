@import "./src/styles/main.scss";

.file-input {
  display: none;
}
.tag-wrapper {
  position: relative;
}
.button-wrapper {
  width: 112px;
}
.tag-group {
  max-width: 405px;
}

.is-multiple {
  .tag-group {
    grid-template-columns: repeat(2, 1fr);
  }
}
.remove-file {
  position: absolute;
  background-color: $m-color-background-tertiary;
  padding: 0;
  top: 9px;
  right: 8px;
  border-radius: 50%;

  &:hover {
    cursor: pointer;
  }
}
