@import "./src/styles/main.scss";

.direction-wrapper {
  text-transform: uppercase;
  background-color: $m-color-primary-20;
  padding: 0px 1px 0px;
  line-height: 8px;

  i {
    margin-top: 2px;
  }
}
