@import "./src/styles/main.scss";

.divider {
  border-bottom: 1px solid #005454;
  margin-left: 0;
  width: 100%;
}
::v-deep #sidebar-logo {
  padding: $m-spacing-2 $m-spacing-3 $m-spacing-1;
  img {
    height: 20px;
  }
}
