$internalSidebarWidth: 215px;

// BREAKPOINTS
$midDesktopBreakpoint: 1200px;
$smallDesktopBreakpoint: 1024px;
$tabletBreakpoint: 768px;
$largeMobileBreakpoint: 600px;
$mobileBreakpoint: 480px;

// MIRANDA
// BRAND
$m-color-brand: #14d64d;
$m-color-brand-accent-1: #00d7d7;
$m-color-brand-accent-2: #84f11e;

// PRIMARY & ACCENT
$m-color-primary-20: #cadbdd;
$m-color-primary-60: #005454;
$m-color-primary-100: #003333;
$m-color-accent-20: #e5f5ff;
$m-color-accent-60: #00629f;
$m-color-accent-100: #0f4261;

// NEUTRALS
$m-color-neutral-0: #fcfcff;
$m-color-neutral-10: #f8f9fb;
$m-color-neutral-20: #e6edf2;
$m-color-neutral-40: #c1ced9;
$m-color-neutral-60: #5e768a;
$m-color-neutral-80: #5c6a76;
$m-color-neutral-90: #3d4a55;
$m-color-neutral-100: #202931;

// Status
$m-color-success-20: #e3f7ef;
$m-color-success-60: #037c5e;
$m-color-success-100: #005e47;
$m-color-danger-20: #ffe9e6;
$m-color-danger-60: #ba1b1b;
$m-color-danger-100: #680003;
$m-color-warning-20: #fff3d6;
$m-color-warning-60: #f8b60e;
$m-color-warning-100: #663f19;

// Spacing
$m-spacing-0-5: 2px;
$m-spacing-1: 4px;
$m-spacing-2: 8px;
$m-spacing-3: 12px;
$m-spacing-4: 16px;
$m-spacing-5: 20px;
$m-spacing-6: 24px;
$m-spacing-8: 32px;
$m-spacing-10: 40px;
$m-spacing-12: 48px;
$m-spacing-14: 56px;
$m-spacing-16: 64px;
$m-spacing-20: 80px;
$m-spacing-30: 120px;

// Typography
$m-font-family-title: 'Plus Jakarta Sans', sans-serif;
$m-font-family-default: 'Manrope';
$m-font-weight-bold: 700;
$m-font-weight-medium: 600;
$m-font-weight-regular: 500;

$m-font-size-1: 0.625rem;
$m-font-size-2: 0.75rem;
$m-font-size-3: 0.875rem;
$m-font-size-4: 1rem;
$m-font-size-5: 1.25rem;
$m-font-size-6: 1.625rem;

$m-line-height-1: 100%;
$m-line-height-2: 125%;
$m-line-height-3: 130%;
$m-line-height-4: 140%;
$m-line-height-5: 150%;

// SEMANTIC COLORS
$m-color-text-primary: $m-color-neutral-100;
$m-color-text-secondary: $m-color-neutral-90;
$m-color-text-tertiary: $m-color-neutral-80;
$m-color-text-placeholder: $m-color-neutral-60;
$m-color-text-disabled: $m-color-neutral-40;
$m-color-text-inverted: $m-color-neutral-0;
$m-color-text-secondary-inverted: $m-color-neutral-40;
$m-color-text-error: $m-color-danger-60;
$m-color-text-highlight: $m-color-accent-60;
$m-color-text-link: $m-color-primary-60;

$m-color-background-primary: $m-color-neutral-0;
$m-color-background-secondary: $m-color-neutral-10;
$m-color-background-tertiary: $m-color-neutral-20;
$m-color-background-highlight: $m-color-primary-20;
$m-color-background-inverted: $m-color-neutral-100;
$m-color-background-secondary-inverted: $m-color-neutral-90;

$black: #000;
