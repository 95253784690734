@import "./src/styles/main.scss";

.steps-only {
  .content-container {
    display: none !important;
  }
}

m-step {
  flex: 1;
  min-width: auto;
}
