@import "./src/styles/main.scss";

m-divider {
  display: block;
  position: absolute;
  width: calc(100% + 48px);
  left: -$m-spacing-4;
  right: 0;

  &.header-divider {
    bottom: -13px;
  }
}
