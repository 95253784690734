@import "./src/styles/main.scss";

.search-header {
  position: sticky;
  top: -$m-spacing-6;
  padding: $m-spacing-4 0;
  z-index: 1;
  background: $m-color-background-primary;

  @media (max-width: $midDesktopBreakpoint) {
    flex-direction: column;
    align-items: flex-start;
    padding-top: 0;
    top: -$m-spacing-4;

    ::v-deep {
      m-field {
        width: 100%;
      }
      m-text-field {
        width: 100% !important;
      }
    }
  }
}

::v-deep {
  .row-items {
    &-2 {
      grid-template-columns: repeat(2, 1fr);

      @media (max-width: $midDesktopBreakpoint) {
        display: flex;
        flex-direction: column;
      }
    }
  }
}
