@import '../helpers';

$spacing-0-5: 2px;
$spacing-1: 4px;
$spacing-2: 8px;
$spacing-3: 12px;
$spacing-4: 16px;
$spacing-5: 20px;
$spacing-6: 24px;
$spacing-8: 32px;
$spacing-10: 40px;
$spacing-12: 48px;
$spacing-14: 56px;
$spacing-16: 64px;
$spacing-20: 80px;
$spacing-30: 120px;

$spacingMap: (
  spacing-0: 0,
  spacing-0-5: $spacing-0-5,
  spacing-1: $spacing-1,
  spacing-2: $spacing-2,
  spacing-3: $spacing-3,
  spacing-4: $spacing-4,
  spacing-5: $spacing-5,
  spacing-6: $spacing-6,
  spacing-8: $spacing-8,
  spacing-10: $spacing-10,
  spacing-12: $spacing-12,
  spacing-14: $spacing-14,
  spacing-16: $spacing-16,
  spacing-20: $spacing-20,
  spacing-30: $spacing-30
);
$spacingSizes: [0, 0-5, 1, 2, 3, 4, 5, 6, 8, 10, 12, 14, 16, 20, 30];
$spacingRules: [ [p, [padding], $spacingMap], [p-x, [padding-left, padding-right], $spacingMap],
  [p-y, [padding-top, padding-bottom], $spacingMap], [p-t, [padding-top], $spacingMap], [p-r,
  [padding-right], $spacingMap], [p-b, [padding-bottom], $spacingMap], [p-l, [padding-left],
  $spacingMap], [m, [margin], $spacingMap], [m-x, [margin-left, margin-right], $spacingMap], [m-y,
  [margin-top, margin-bottom], $spacingMap], [m-t, [margin-top], $spacingMap], [m-r, [margin-right],
  $spacingMap], [m-b, [margin-bottom], $spacingMap], [m-l, [margin-left], $spacingMap], [gap, [gap],
  $spacingMap]];

@each $item in $spacingRules {
  @include generate-with-breakpoints(
    nth($item, 1),
    nth($item, 2),
    nth($item, 3),
    $spacingSizes,
    spacing
  );
}

.m-auto {
  margin: auto !important;
}
