@import "./src/styles/main.scss";

.amenities-grid {
  grid-template-columns: repeat(2, 1fr);
  height: 100%;
  @media (max-width: $smallDesktopBreakpoint) {
    display: flex;
    flex-wrap: wrap;
  }
}

.yard-map-img {
  max-width: 100%;
  width: 600px;
  max-height: 400px;
}
