@import "./src/styles/main.scss";

.timestamp-input {
  overflow: hidden;
}

:host {
  --m-grid-min-column-width: 30px !important;
}

.timestamp-group {
  display: grid;
  grid-template-columns: 1fr 1fr 92px;
  grid-gap: $m-spacing-2;

  @media (max-width: $tabletBreakpoint) {
    width: auto !important;
  }
}
