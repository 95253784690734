@import "./src/styles/main.scss";

.appointment-list {
  display: flex;
  flex-direction: column;
}
.header {
  padding: $m-spacing-3;
  background: $m-color-background-primary;
  border: 1px solid var(--line-divider, #e6edf2);
  border-bottom: none;
  border-radius: 5px 5px 0 0;
  position: sticky;
  top: -24px;
  z-index: 10;
}
.appointment-search-field {
  max-width: 350px;
}

.close-icon {
  cursor: pointer;
}

.filter-bar {
  display: flex;
  justify-content: space-between;
}

.clickable-rows {
  cursor: pointer;
}

.appointment-list-table {
  background-color: $m-color-background-primary !important;
  flex: 1;
}

.table-wrapper {
  border: 1px solid var(--line-divider, #e6edf2);
  border-top: none;
  border-radius: 0 0 5px;
}

::v-deep {
  .v-data-table .v-data-table__wrapper {
    overflow: unset;
  }

  .v-data-table-header {
    position: sticky;
    top: 45px;
    z-index: 1;

    th {
      background: $m-color-background-secondary !important;
      border-top: 1px solid var(--line-divider, #e6edf2);
      border-bottom: 1px solid var(--line-divider, #e6edf2) !important;
    }
  }

  tr {
    background-color: #fff;
  }

  .v-data-footer {
    position: sticky;
    bottom: 0;
    background: white;
    z-index: 1;
    width: 100%;
  }
}
