@import "./src/styles/main.scss";

.widgets {
  @media (max-width: $smallDesktopBreakpoint) {
    flex-direction: column;
  }
}

.widget {
  width: 100%;
  @media (max-width: $smallDesktopBreakpoint) {
    flex: 1;
  }
}
