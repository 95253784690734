@import "./src/styles/main.scss";

.footer-actions {
  position: sticky;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  padding: $m-spacing-4 $m-spacing-0-5;
  background: $m-color-background-primary;
}
.appointment-summary {
  position: sticky;
  top: 0;
}

.mobile-steps {
  display: block;
  @media (min-width: $smallDesktopBreakpoint) {
    display: none;
  }
}

@media (max-width: $smallDesktopBreakpoint) {
  ::v-deep {
    [class*='sidebar-cards'] .sidebar {
      display: none;
    }
    m-header:not(.auth-bar) m-header-actions {
      display: none;
    }
  }
}
