@import "./src/styles/main.scss";

.v-application {
  .vue-notification-group {
    width: 500px !important;
    max-width: calc(100% - 2rem);
  }

  .vue-notification-wrapper {
    height: auto !important;
    &:not(:first-child) {
      margin-top: 0.5rem;
    }
  }

  .app-notification {
    border-radius: 5px;
    position: relative;
    padding: $m-spacing-4;
    display: flex;
    align-items: center;
    gap: $m-spacing-4;

    &.app-success {
      background-color: $m-color-success-20 !important;
      border-bottom: 3px solid $m-color-success-60 !important;
      color: #000;
    }

    &.app-error {
      background-color: $m-color-danger-20 !important;
      border-bottom: 3px solid $m-color-danger-60 !important;
      color: #000;

      i.type-icon {
        color: $m-color-danger-60 !important;
      }
    }

    &.app-warning {
      background-color: $m-color-warning-20;
      border-bottom: 3px solid $m-color-warning-60 !important;
      color: #000;
    }

    &.app-info {
      background-color: $m-color-accent-20 !important;
      border-bottom: 3px solid $m-color-accent-60 !important;
      color: #000;
    }

    .title {
      font-size: 1rem !important;
      margin-bottom: 0;
      line-height: 1.25rem;
    }
  }
}
