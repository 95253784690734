@import 'partials/variables';
@import '@satellite/styles/partials/theme/intermediary_theme';
@import '@satellite/styles/partials/theme/status_theme';
@import '@satellite/styles/partials/theme/spacing_theme';
@import '@satellite/styles/partials/theme/font_size_theme';

/*
 TODO: ADD TO SATELLITE WHEN WE START MIGRATING HELIOS AND OTHER UIS
 */

.is {
  &__block {
    display: block;
  }
  &__inline {
    display: inline;
  }
  &__inline-block {
    display: inline-block;
  }
  &__relative {
    position: relative;
  }
  &__overflow-auto {
    overflow: auto;
  }
}

.full-vh {
  height: 100vh;
  height: 100dvh;
}

width-auto {
  width: auto;
}
.full-width,
.width-100 {
  width: 100%;
}
.full-height,
.height-100 {
  height: 100%;
}
.height-50 {
  height: 50%;
}
.width-50 {
  width: 50%;
}
.flex {
  display: flex;
}
.flex {
  &-column {
    flex-direction: column;
  }
  &-row {
    flex-direction: row;
  }
  &-0 {
    flex-grow: 0;
  }
  &-1 {
    flex-grow: 1;
  }
  &-space-between {
    display: flex;
    justify-content: space-between;
  }
  &-nowrap {
    flex-wrap: nowrap;
  }
}

.uppercase {
  text-transform: uppercase;
}

/*
 END OF FUTURE SATELLITE ABSTRACTIONS
 */

#html {
  background-color: $m-color-background-primary;
  * {
    box-sizing: border-box;
  }
}

#body {
  margin: 0;
}

// So many "importants" here - we have to override inline styles on the element that we don't have control over directly
.internal-page {
  #launcher {
    right: 32px !important;
    right: 0;
    height: 60px !important;
    width: 60px !important;
  }
}

.registration-page,
login-page {
  #launcher {
    @media (max-width: $smallDesktopBreakpoint) {
      position: absolute !important;
      left: auto !important;
      right: $m-spacing-8 !important;
      top: $m-spacing-8 !important;
      bottom: auto !important;
    }
  }
}

#app-wrapper {
  height: 100%;
  @media (max-width: $largeMobileBreakpoint) {
    height: 100dvh;
  }
}

#main-content {
  min-width: auto;
}

#main-sidebar {
  background-color: $m-color-primary-100;
}
#app-body {
  height: 100%;
}

.hide-rows-per-page {
  .v-data-footer__select {
    opacity: 0;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.margin-auto {
  margin: auto;
}

.flex-contain {
  min-height: 0;
}

.overflow-auto {
  overflow: auto;
}

.v-menu__content {
  z-index: 100;
}

.clickable {
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

// Tablet breakpoint and up
.display-desktop {
  display: none;

  @media (min-width: $tabletBreakpoint) {
    display: inline-block;
    &.flex {
      display: flex;
    }
  }
}

// Tablet breakpoint and down
.display-tablet {
  display: none;
  @media (max-width: $tabletBreakpoint) {
    display: inline-block;
    &.flex {
      display: flex;
    }
  }
}

// large mobile breakpoint and down
.display-mobile {
  display: none;
  @media (max-width: $largeMobileBreakpoint) {
    display: inline-block;
    &.flex {
      display: flex;
    }
  }
}

m-field-label {
  text-align: left;
}
