@import "./src/styles/main.scss";

@import 'vue-tel-input/dist/vue-tel-input.css';

.country-code {
  max-width: 125px;
}
.phone-field {
  max-width: 275px;
}

.vue-tel-input {
  border: none;
  &:focus-within {
    outline-style: none;
    box-shadow: none;
    border-color: transparent;
  }
}

::v-deep {
  input {
    min-height: 37px !important;
    max-height: 37px !important;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    font-synthesis: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
    font-family: var(--m-font-family-default, 'Manrope', sans-serif);
    line-height: var(--m-line-height-5, 150%);
    font-size: var(--m-font-size-3, 0.875rem);
    font-weight: var(--m-font-weight-regular, 500);
    align-self: stretch;
    border: none;
    outline: none;
    flex: 1;
    min-width: 0;
    padding-inline-end: 0;
    padding-inline-start: 0;
    padding-block: var(--m-text-field-padding-y);
    background-color: var(--m-text-field-background-color);
    color: var(--m-text-field-color);
    --m-text-field-background-color: rgba(var(--m-color-background-primary, 252, 252, 255), 1);
    --m-text-field-border-color: rgba(var(--m-color-border, 193, 206, 217), 1);
    --m-text-field-height: var(--m-global-height-default, 2.5rem);
    --m-text-field-padding-y: var(--m-spacing-2, 8px);
    --m-text-field-placeholder-color: rgba(var(--m-color-text-placeholder, 94, 118, 138), 1);
    --m-text-field-color: rgba(var(--m-color-text-primary, 32, 41, 49), 1);
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    font-synthesis: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
    font-family: var(--m-font-family-default, 'Manrope', sans-serif);
    line-height: var(--m-line-height-5, 150%);
    font-size: var(--m-font-size-3, 0.875rem);
    font-weight: var(--m-font-weight-regular, 500);
    transition: background-color 0.35s ease-in-out 0s, border-color 0.35s ease-in-out 0s;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    gap: var(--m-spacing-2, 8px);
    position: relative;
    box-sizing: border-box;
    width: 100%;
    block-size: var(--m-text-field-height);
    padding-inline-end: var(--m-spacing-2, 8px);
    padding-inline-start: var(--m-spacing-2, 8px);
    background-color: var(--m-text-field-background-color);
    border-radius: var(--m-border-radius-s, 4px);
    border: 1px solid var(--m-text-field-border-color);
    &:focus {
      --m-text-field-border-color: rgba(var(--m-color-primary-100, 0, 51, 51), 1);
    }
  }

  .vti {
    &__dropdown {
      min-height: 37px !important;
      max-height: 37px !important;
      width: 100px;
      margin-right: 16px;
      --m-text-field-background-color: rgba(var(--m-color-background-primary, 252, 252, 255), 1);
      --m-text-field-border-color: rgba(var(--m-color-border, 193, 206, 217), 1);
      --m-text-field-height: var(--m-global-height-default, 2.5rem);
      --m-text-field-padding-y: var(--m-spacing-2, 8px);
      --m-text-field-placeholder-color: rgba(var(--m-color-text-placeholder, 94, 118, 138), 1);
      --m-text-field-color: rgba(var(--m-color-text-primary, 32, 41, 49), 1);
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      font-synthesis: none;
      text-rendering: optimizeLegibility;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      -webkit-text-size-adjust: 100%;
      font-family: var(--m-font-family-default, 'Manrope', sans-serif);
      line-height: var(--m-line-height-5, 150%);
      font-size: var(--m-font-size-3, 0.875rem);
      font-weight: var(--m-font-weight-regular, 500);
      transition: background-color 0.35s ease-in-out 0s, border-color 0.35s ease-in-out 0s;
      display: inline-flex;
      justify-content: flex-start;
      gap: var(--m-spacing-2, 8px);
      position: relative;
      box-sizing: border-box;
      block-size: var(--m-text-field-height);
      padding-inline-end: var(--m-spacing-2, 8px);
      padding-inline-start: var(--m-spacing-2, 8px);
      background-color: var(--m-text-field-background-color);
      border-radius: var(--m-border-radius-s, 4px);
      border: 1px solid var(--m-text-field-border-color);

      &:focus {
        --m-text-field-border-color: rgba(var(--m-color-primary-100, 0, 51, 51), 1);
      }
    }
    &__selection {
      justify-content: space-between;
    }
    &__dropdown-list {
      padding: 0;
      width: 250px;
    }
    &__dropdown-item {
      padding: $m-spacing-2 $m-spacing-2 !important;
    }
    /**
      Removing via css instead of component options to preserve the dropdown styling
     */
    &__flag-wrapper {
      display: none !important;
    }
  }

  .tel-input {
    @media (max-width: $tabletBreakpoint) {
      width: 100% !important;
    }
  }
}
