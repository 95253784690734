@import 'vuetify/src/styles/styles.sass';

/* Generate helper classes */
@mixin generate($prefix, $properties, $map, $sizes, $mapKeyPrefix) {
  @each $size in $sizes {
    .#{$prefix}-#{$size} {
      @each $property in $properties {
        #{$property}: map-get($map, #{$mapKeyPrefix}-#{$size});
      }
    }
  }
}

$breakpoints: (
  'xs': map-get($display-breakpoints, 'xs-only'),
  'sm': map-get($display-breakpoints, 'sm-and-up'),
  'md': map-get($display-breakpoints, 'md-and-up'),
  'lg': map-get($display-breakpoints, 'lg-and-up'),
  'xl': map-get($display-breakpoints, 'xl-only')
);

@mixin generate-with-breakpoints($prefix, $properties, $map, $sizes, $type) {
  // Generate default classes (no breakpoint)
  @include generate($prefix, $properties, $map, $sizes, $type);

  // Generate breakpoint-specific classes
  @each $breakpoint, $value in $breakpoints {
    @media #{$value} {
      @each $size in $sizes {
        $key: spacing-#{$size};
        @if map-has-key($map, $key) {
          .#{$prefix}-#{$breakpoint}-#{$size} {
            @each $property in $properties {
              #{$property}: map-get($map, $key);
            }
          }
        }
      }
    }
  }
}
