@import "./src/styles/main.scss";

@import '@/styles/main.scss';

// Disabled auto-zoom on inputs for mobile
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select,
  textarea,
  input {
    font-size: 16px !important;
  }
}
#main-content {
  padding: 0;
  height: 100%;
}
m-sidebar {
  height: 100%;
}
#main-sidebar {
  height: 100%;
  border-radius: 0;
  width: $internalSidebarWidth;

  @media (max-width: $tabletBreakpoint) {
    width: 100%;
    padding: 0;
  }

  @media (max-width: $tabletBreakpoint) {
    background-color: $m-color-primary-100;
    height: 100%;
    position: absolute;
    left: -150%;
    top: 0;
    width: 100%;
    z-index: 100;
    transition: left 0.4s ease-in-out;

    &.open {
      left: 0;
    }
  }
}

#date-picker-dialog {
  border: none;
  padding: 0;
  margin: 0;
  position: absolute;
  min-width: auto !important;
  overflow: hidden;
  width: 290px;
  height: 290px;

  .v-menu__content {
    position: static;
    min-width: auto !important;
    transition: none !important;
  }
}
