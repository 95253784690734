@import "./src/styles/main.scss";

#sidebar {
  background-size: cover;
  background-image: url('@/../public/images/auth-sidebar.png');
  background-position: center;
  background-color: var(--primary-primary-100, #033);
  background-blend-mode: multiply;
  border-radius: 0;
  opacity: 0.85;
  width: 600px;
  padding: $m-spacing-8;

  @media (max-width: $midDesktopBreakpoint) {
    width: 424px;
    padding: $m-spacing-4;
  }

  @media (max-width: $smallDesktopBreakpoint) {
    height: 125px;
  }

  @media (max-width: $tabletBreakpoint) {
    margin-top: 100px;
  }

  .logo-tag {
    @media (max-width: $midDesktopBreakpoint) {
      width: 400px;
    }
    @media (max-width: $smallDesktopBreakpoint) {
      width: 100%;
    }
  }

  .sidebar-content {
    width: 395px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: $m-spacing-4;
    justify-content: center;

    @media (max-width: $smallDesktopBreakpoint) {
      flex-direction: row;
      width: 90%;
      align-items: center;
      justify-content: space-between;
    }
    @media (max-width: $tabletBreakpoint) {
      width: 100%;
      //gap: 0 !important;
    }
  }
}
.sidebar-component {
  height: 100%;
  @media (max-width: $smallDesktopBreakpoint) {
    height: auto;
  }
}
#main-content {
  @media (max-width: $smallDesktopBreakpoint) {
    min-width: 100% !important;
  }
}
#login-form-container {
  width: 525px;
  margin: 0 auto;
  padding: $m-spacing-8;
  height: 100%;

  @media (max-width: 1200px) {
    padding: $m-spacing-4;
  }

  @media (max-width: $smallDesktopBreakpoint) {
    width: 100%;
    max-width: 600px;
    height: auto;
  }
}

.email-not-verified-container {
  max-width: 530px;
  margin: auto;
}

.hide-mobile {
  @media (max-width: $largeMobileBreakpoint) {
    display: none;
  }
}
.hide-tablet {
  @media (max-width: $smallDesktopBreakpoint) {
    display: none;
  }
}
.hide-desktop {
  @media (min-width: $smallDesktopBreakpoint) {
    display: none;
  }
}
