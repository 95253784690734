@import "./src/styles/main.scss";

.value-grid {
  grid-template-columns: repeat(5, 1fr);
}
::v-deep .sidebar {
  @media (max-width: $smallDesktopBreakpoint) {
    order: 2;
  }
}

.value-grid {
  @media (max-width: $smallDesktopBreakpoint) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: $largeMobileBreakpoint) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: $mobileBreakpoint) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.warehouse-details-card {
  @media (max-width: $smallDesktopBreakpoint) {
    flex-direction: column;
  }
}
