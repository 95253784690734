@import "./src/styles/main.scss";

.step-indicator-group {
  margin: $m-spacing-2 0;
}
.step-indicator {
  height: 5px;
  background-color: $m-color-neutral-40;
  flex-grow: 1;
  transition: background-color 0.4s ease;

  &.requirement-met {
    background-color: $m-color-danger-100;
  }
  &.requirements-have-been-met {
    background-color: $m-color-success-100;
  }
}
