@import "./src/styles/main.scss";

/**
Gross - but since Miranda does not have this component yet, we copied base button styles for consistency
 */
.sidebar-button {
  color: $m-color-text-inverted;
  border: none;
  transition: color 0.35s ease-in-out 0s, background-color 0.35s ease-in-out 0s,
    border-color 0.35s ease-in-out 0s;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  font-family: var(--m-font-family-default, 'Manrope', sans-serif);
  line-height: var(--m-line-height-1, 100%);
  font-weight: var(--m-font-weight-bold, 700);
  font-size: var(--m-button-font-size);
  cursor: pointer;
  display: flex;
  block-size: 100%;
  inline-size: 100%;
  padding-inline: var(--m-button-padding-x);
  padding-block: var(--m-button-padding-y);
  border-radius: var(--m-button-border-radius);
  --m-button-background-color: transparent;
  --m-button-base-size: var(--m-global-height-default, 2.5rem);
  --m-button-border-color: rgba(var(--m-color-neutral-90, 61, 74, 85), 1);
  --m-button-border-radius: var(--m-border-radius-s, 4px);
  --m-button-color: rgba(var(--m-color-text-secondary, 61, 74, 85), 1);
  --m-button-outline-color: rgba(var(--m-color-primary-100, 0, 51, 51), 1);
  --m-button-outline-offset: 2px;
  --m-button-padding-x: var(--m-spacing-2, 8px);
  --m-button-padding-y: var(--m-spacing-3, 12px);
  --m-button-font-size: var(--m-font-size-3, 0.875rem);

  &:hover {
    opacity: 0.8;
  }

  &[disabled] {
    background-color: $m-color-primary-20 !important;
    opacity: 0.1;
  }

  &.active {
    background-color: $m-color-primary-60 !important;
  }
}
