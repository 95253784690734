@import "./src/styles/main.scss";

m-header-title {
  align-items: center;
  display: flex;
}
m-header-content {
  flex-grow: 1;
}

m-header-actions {
  align-items: center;
  justify-content: flex-end;
}

.should-collapse {
  m-header-actions {
    @media (max-width: $smallDesktopBreakpoint) {
      > m-button:not([variant='icon']) {
        flex: 1;
      }
    }
  }
}
