@import "./src/styles/main.scss";

.input-wrapper {
  margin-top: 15px;
  padding-bottom: 2px;
}

[status='danger'] {
  ::v-deep {
    [role='combobox'] {
      border-color: $m-color-text-error;
    }
  }
}

.close-icon {
  &:hover {
    cursor: pointer;
  }
}

::v-deep {
  input {
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    font-synthesis: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
    font-family: var(--m-font-family-default, 'Manrope', sans-serif);
    line-height: var(--m-line-height-5, 150%);
    font-size: var(--m-font-size-3, 0.875rem);
    font-weight: var(--m-font-weight-regular, 500);
    border: none;
    outline: none;
    flex: 1;
    min-width: 0;
    padding-inline-end: 0;
    padding-inline-start: 0;
    padding-block: var(--m-text-field-padding-y);
    background-color: var(--m-text-field-background-color);
    color: var(--m-text-field-color);
  }

  fieldset {
    display: none;
  }

  .v-select__selections {
    min-height: 40px !important;
    max-height: 40px !important;
    height: 37px !important;
    padding-top: 9px !important;
  }

  [role='combobox'] {
    min-height: 40px !important;
    max-height: 40px !important;
    --m-text-field-background-color: rgba(var(--m-color-background-primary, 252, 252, 255), 1);
    --m-text-field-border-color: rgba(var(--m-color-border, 193, 206, 217), 1);
    --m-text-field-height: var(--m-global-height-default, 2.5rem);
    --m-text-field-padding-y: var(--m-spacing-2, 8px);
    --m-text-field-placeholder-color: rgba(var(--m-color-text-placeholder, 94, 118, 138), 1);
    --m-text-field-color: rgba(var(--m-color-text-primary, 32, 41, 49), 1);
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    font-synthesis: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
    font-family: var(--m-font-family-default, 'Manrope', sans-serif);
    line-height: var(--m-line-height-5, 150%);
    font-size: var(--m-font-size-3, 0.875rem);
    font-weight: var(--m-font-weight-regular, 500);
    transition: background-color 0.35s ease-in-out 0s, border-color 0.35s ease-in-out 0s;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    gap: var(--m-spacing-2, 8px);
    position: relative;
    box-sizing: border-box;
    width: 100%;
    block-size: var(--m-text-field-height);
    padding-inline-end: var(--m-spacing-2, 8px);
    padding-inline-start: var(--m-spacing-2, 8px);
    background-color: var(--m-text-field-background-color);
    border-radius: var(--m-border-radius-s, 4px);
    border: 1px solid var(--m-text-field-border-color);
    &:focus-within {
      --m-text-field-border-color: rgba(var(--m-color-primary-100, 0, 51, 51), 1);
    }
  }

  label.v-label,
  label.v-label--active {
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    font-synthesis: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
    font-family: var(--m-font-family-default, 'Manrope', sans-serif);
    line-height: var(--m-line-height-5, 150%);
    font-size: var(--m-font-size-3, 0.875rem) !important;
    font-weight: var(--m-font-weight-regular, 500);
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0;
    padding: 0;
    color: rgba(var(--m-color-text-tertiary, 92, 106, 118), 1) !important;
    transform: initial !important;
    left: -12px !important;
    top: -24px !important;
  }

  input {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .v-input__append-inner {
    margin-top: $m-spacing-0-5 !important;
    margin-right: -2px;
    padding: 8px 0;
  }
}
