@import "./src/styles/main.scss";

.sidebar {
  width: 285px;
  @media (max-width: $smallDesktopBreakpoint) {
    width: 100% !important;
  }
}
m-box {
  padding: 0;

  m-card {
    display: block;
    height: 100%;
  }
}

m-sidebar {
  @media (max-width: $smallDesktopBreakpoint) {
    width: 100%;
    flex-wrap: nowrap;
    flex-direction: column;
  }
}
