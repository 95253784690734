@import "./src/styles/main.scss";

// TODO: Report bug - Miranda dropdown always shows "large" size styling
:root .time-button {
  width: 100px;

  &.active {
    border: 1px solid var(--Accent-100, #0f4261) !important;
    background: var(--Background-secondary, #f8f9fb) !important;
  }
}

.is-loading {
  .date-header {
    @media (max-width: $midDesktopBreakpoint) {
      display: none;
    }
  }
}
#availability-grid {
  grid-template-columns: repeat(5, 1fr);

  m-stack.slots {
    m-button {
      width: 100%;
      max-width: 120px;
      min-width: 60px;
    }
  }

  @media (max-width: $midDesktopBreakpoint) {
    grid-template-columns: repeat(1, 1fr);

    > m-group {
      justify-content: flex-start;
    }

    > m-stack.slots {
      display: grid !important;
      gap: $m-spacing-4;
      justify-content: space-between;
      grid-template-columns: repeat(3, 1fr);
    }

    .slots,
    .date-header {
      &.flex-order {
        &-0 {
          order: 0;
        }
        &-1 {
          order: 1;
        }
        &-2 {
          order: 2;
        }
        &-3 {
          order: 3;
        }
        &-4 {
          order: 4;
        }
        &-5 {
          order: 5;
        }
        &-6 {
          order: 6;
        }
        &-7 {
          order: 7;
        }
        &-8 {
          order: 8;
        }
        &-9 {
          order: 9;
        }
        &-10 {
          order: 10;
        }
      }
    }
  }

  @media (max-width: $smallDesktopBreakpoint) {
    > m-stack.slots {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  @media (max-width: $tabletBreakpoint) {
    > m-stack.slots {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}
.header {
  background-color: $m-color-background-primary;
  position: sticky;
  top: -$m-spacing-6;
  padding: $m-spacing-4 1px;
  margin-top: -$m-spacing-4;
  margin-left: -1px;
  width: calc(100% + 2px);
  z-index: 1;

  @media (max-width: $midDesktopBreakpoint) {
    flex-direction: column;
    align-items: flex-start;
    padding-top: 0;
    top: -$m-spacing-4;

    m-field {
      width: 100%;
    }
  }
}
