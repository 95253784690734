@import "./src/styles/main.scss";

.warehouse-logo {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid $m-color-background-tertiary;
}

.link {
  // Miranda link color
  color: $m-color-text-link;
  font-weight: 700;

  // body-md-underline
  font-family: var(--m-font-family-default, 'Manrope', sans-serif);
  font-size: 14px;
  font-style: normal;
  line-height: 140%; /* 19.6px */
  text-decoration-line: underline;
}
