@import "./src/styles/main.scss";

.pagination {
  :deep {
    &.right {
      .v-pagination {
        justify-content: flex-end;
      }
    }
    &.left {
      .v-pagination {
        justify-content: flex-start;
      }
    }
    .v-pagination {
      padding: $m-spacing-2 $m-spacing-4;

      &__item,
      .v-pagination__navigation {
        border: none;
        box-shadow: none;
        margin: 0;
        color: $m-color-text-primary !important;
        opacity: 0.7;

        &--disabled {
          opacity: 0.2;
        }

        &--active {
          background-color: transparent !important;
          color: $m-color-text-primary !important;
          opacity: 1;
          font-weight: bold;
        }
      }
    }
  }
}
