@import "./src/styles/main.scss";

.body-wrapper {
  height: 100%;
}

.page-wrapper {
  overflow: auto;
  position: relative;
}

.v-menu__content {
  transform: translateY(35px);
  min-width: 250px !important;
}

.logout-action {
  &:hover {
    cursor: pointer !important;
  }
}

.auth-bar {
  padding-top: 3px;
  padding-bottom: 3px;
}

.logo-spacing {
  visibility: hidden;
  ::v-deep {
    img {
      height: 20px;
    }
  }

  @media (max-width: $tabletBreakpoint) {
    display: none;
  }
}

#page-body {
  background: $m-color-background-secondary;
  display: flex;
  flex-direction: column;
  min-height: 0;
  overflow: hidden;
  flex: 1;
  padding-bottom: $m-spacing-6;
}

.page-body-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 1280px;
  margin: auto;
}

.page-footer {
  position: absolute;
  background-color: #fcfcff;
  border-top: 1px solid var(--line-divider, #e6edf2);
  bottom: 0;
  left: 0;
  width: 100%;

  &.has-sidebar {
    left: calc(#{$internalSidebarWidth} + 2px);
    width: calc(100% - #{$internalSidebarWidth} - 2px);
  }

  @media (max-width: $smallDesktopBreakpoint) {
    &.has-sidebar {
      left: 0;
      width: 100%;
    }
  }
}
