@import "./src/styles/main.scss";

/**
Outer styles on body/html and the wrappers that do not work in a scoped style block
 */
@media screen {
  #print-content {
    display: none;
  }
  .printable-window {
    display: none;
  }
}

@media print {
  @page {
    margin: 0;
    size: letter;
  }

  #html,
  #body {
    margin: 0 !important;
    height: 95% !important;
    overflow: clip !important;
  }
  #body *:not(#print-content):not(#print-content *):not(.printable-window) {
    display: none !important;
  }
  #print-content {
    font-family: 'Roboto';
    position: absolute;
    left: 0;
    top: 0;
    margin: 1.6cm 1.6cm;
    width: calc(100% - 3.2cm);
  }

  .printable-window {
    .header-spacing {
      margin-top: 1.6cm;
    }
  }
}
