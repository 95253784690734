@import "./src/styles/main.scss";

// TODO: Make this an official loader outside of this component?
.dot-flashing {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: $m-color-text-tertiary;
  color: $m-color-text-tertiary;
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.25s;
}
.dot-flashing::before,
.dot-flashing::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: $m-color-text-tertiary;
  color: $m-color-text-tertiary;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0s;
}
.dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: $m-color-text-tertiary;
  color: $m-color-text-tertiary;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0.5s;
}

@keyframes dot-flashing {
  0% {
    background-color: $m-color-text-tertiary;
  }
  50%,
  100% {
    background-color: rgba(92, 106, 118, 0.2);
  }
}
