@import "./src/styles/main.scss";

.warehouse-logo {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid $m-color-background-tertiary;
}
.nobreak {
  word-break: break-all;
}
