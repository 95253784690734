@import "./src/styles/main.scss";

:root {
  .clickable {
    &:hover {
      --m-card-background-color: #{$m-color-background-tertiary} !important;
      cursor: pointer;
      opacity: 1;
    }
  }
  .hide-outer-card {
    padding-bottom: $m-spacing-4;
    > m-card {
      background: transparent !important;
      border: none !important;
      box-sizing: border-box;
    }
    > m-card-body {
      --m-card-body-padding-y: 0;
      --m-card-body-padding-x: 0;
    }
  }

  .no-items > m-card-body .m-card-body {
    height: calc(100% - 40px);
    box-sizing: border-box;
  }
}

.card-grid-container {
  display: flex;
  min-height: 0;

  > m-card-title {
    top: 0;
    position: sticky;
    z-index: 1;
    padding-bottom: 16px;
  }
}

.card-grid {
  overflow: hidden;
  padding-bottom: 2px;

  &.row-items {
    &-1 {
      grid-template-columns: repeat(1, 1fr);

      @media (max-width: $largeMobileBreakpoint) {
        display: flex;
        flex-direction: column;
      }
    }
    &-2 {
      grid-template-columns: repeat(2, 1fr);

      @media (max-width: $largeMobileBreakpoint) {
        display: flex;
        flex-direction: column;
      }
    }
    &-3 {
      grid-template-columns: repeat(3, 1fr);

      @media (max-width: $tabletBreakpoint) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media (max-width: $largeMobileBreakpoint) {
        display: flex;
        flex-direction: column;
      }
    }
    &-4 {
      grid-template-columns: repeat(4, 1fr);

      @media (max-width: $midDesktopBreakpoint) {
        grid-template-columns: repeat(3, 1fr);
      }
      @media (max-width: $tabletBreakpoint) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media (max-width: $largeMobileBreakpoint) {
        display: flex;
        flex-direction: column;
      }
    }
    &-5 {
      grid-template-columns: repeat(5, 1fr);

      @media (max-width: $midDesktopBreakpoint) {
        grid-template-columns: repeat(4, 1fr);
      }
      @media (max-width: $tabletBreakpoint) {
        grid-template-columns: repeat(3, 1fr);
      }
      @media (max-width: $largeMobileBreakpoint) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media (max-width: $mobileBreakpoint) {
        display: flex;
        flex-direction: column;
      }
    }
  }

  @media (max-width: $largeMobileBreakpoint) {
    display: flex;
    flex-direction: column;
  }
}
