@import "./src/styles/main.scss";

#sidebar {
  background-size: cover;
  background-image: url('@/../public/images/auth-sidebar.png');
  background-position: center;
  background-color: var(--primary-primary-100, #033);
  background-blend-mode: multiply;
  border-radius: 0;
  opacity: 0.85;
  width: 600px;
  padding: $m-spacing-8;

  @media (max-width: $midDesktopBreakpoint) {
    width: 424px;
    padding: $m-spacing-4;
  }
}
.sidebar-component {
  height: 100%;
  @media (max-width: $smallDesktopBreakpoint) {
    height: auto;
  }
}
.heading {
  padding-top: $m-spacing-10;
  padding-bottom: $m-spacing-20;
  display: flex;
  flex-direction: row;
  gap: $m-spacing-4;

  @media (max-width: $midDesktopBreakpoint) {
    padding: $m-spacing-2;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: $m-spacing-2;
  }
}
#main-content {
  @media (max-width: $midDesktopBreakpoint) {
    min-width: 100% !important;
  }
}
#registration-form-container {
  width: 525px;
  margin: 0 auto;
  justify-content: safe center;
  padding: $m-spacing-8;
  height: 100%;

  @media (max-width: 1200px) {
    padding: $m-spacing-4;
  }

  @media (max-width: $midDesktopBreakpoint) {
    width: 100%;
    max-width: 600px;
    height: auto;
  }
}
.benefits-list {
  padding-right: $m-spacing-30;

  @media (max-width: $midDesktopBreakpoint) {
    padding-right: $m-spacing-10;
  }
}
.list-item {
  padding-right: $m-spacing-6;
}

.hide-mobile {
  @media (max-width: $midDesktopBreakpoint) {
    display: none;
  }
}
.hide-mid-desktop {
  @media (min-width: $midDesktopBreakpoint) {
    display: none;
  }
}
