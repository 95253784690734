@import "./src/styles/main.scss";

::v-deep {
  m-header-actions {
    align-items: center;
  }
}
.book-appointment-btn {
  max-width: 200px;
  align-self: center;
}
.big-number-box-container {
  @media (max-width: $tabletBreakpoint) {
    display: none;
  }
}
